<div fxLayout="row" class="indicatorWrapper" id="step-indicator-container">
  <ng-container *ngFor="let step of steps">
    <div *ngIf="step.visible" class="stepIndicator" (click)="emitStepClick(step)" (keydown.enter)="emitStepClick(step)"
      [ngClass]="{ clickable: step.checked, checked: step.checked && !step.error, active: step.active, error: step.error}"
      [attr.id]="'step-'+step.id">
      <div fxLayout="row" class="innerWrapper">
        <div class="circle">
          <div class="wrapper">
            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>

            <svg class="cross" viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg">
              <circle class="cross__circle" cx="26" cy="26" r="25" fill="none" />
              <g class="cross__check" transform="translate(13,13)" stroke="#FFF">
                <path d="m0 26 26-26" />
                <path d="m0 0 26 26" />
              </g>
            </svg>

          </div>
        </div>
        <div class="label">{{ step.label }}</div>
      </div>
    </div>
  </ng-container>
</div>