import { Component, Input, OnChanges, Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { IndicatorStep } from "../interfaces";


@Component({
  selector: "lib-step-indicator",
  templateUrl: "./step-indicator.component.html",
  styleUrls: ["./step-indicator.component.scss"]
})
export class StepIndicatorComponent implements OnChanges {

  @Input() steps: IndicatorStep[];

  @Input() activeStep: number;

  @Input() errorStep: number;

  @Output() stepClicked = new EventEmitter<number>();

  constructor(

  ) {
  }
  ngOnChanges(): void {
    let scrollToSep = this.activeStep;
    if (this.errorStep) {
      scrollToSep = this.errorStep;
    }

    this.scrollActiveIntoView(scrollToSep);
  }

  private scrollActiveIntoView(stepId: number) {
    const container = document.getElementById("step-indicator-container");
    const el = document.getElementById(`step-${stepId}`);
    const left = stepId > 1 ? el?.offsetLeft - 10 : 0;
    container.scrollTo({ left, behavior: "smooth" });
  }

  public emitStepClick(step: IndicatorStep) {
    if (step.checked) {
      this.stepClicked.emit(step.id);
    }
  }




}
