import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AbstractSchadenMeldungFunction } from "../AbstractSchadenMeldungFunction";

@Component({
  selector: "lib-ansprechpartner-daten-step",
  templateUrl: "./ansprechpartner-daten-step.component.html",
  styleUrls: ["./ansprechpartner-daten-step.component.scss"]
})
export class AnsprechpartnerDatenStepComponent extends AbstractSchadenMeldungFunction implements OnInit {

  @Input()
    ansprechpartnerFormGroup: FormGroup;

  constructor(translate: TranslateService) {
    super(translate);
  }

  ngOnInit(): void {
  }

  public infoToolTip = this.translate.instant("schaden.schadenmeldung.formular.ansprechpartner.tooltip");

  getFormFieldErrorMessageComponent(key: string): string {
    return this.getFormFieldErrorMessage(this.ansprechpartnerFormGroup.errors);
  }

}
