<form *ngIf="schadenMeldungFormGroup" [formGroup]="schadenMeldungFormGroup">
  <mat-card class="schadenmeldungCard" style="margin-bottom: 0.5rem !important">
    <mat-card-content>
      <lib-step-indicator [steps]="indicatorSteps" [activeStep]="activeStep" [errorStep]="errorStep" (stepClicked)="stepClicked($event)"></lib-step-indicator>
    </mat-card-content>
  </mat-card>

  <mat-card class="schadenmeldungCard" style="height: 100%; max-height: calc(100vh - 110px)">

    <mat-card-content style="
        padding: 0.75rem 0;
        max-height: calc(100vh - 150px);
        margin: 0;
        overflow: auto;
      " cdkScrollable>
      <div class="schadenmeldungFormWrapper">
        <div class="formStepWrapper" #formStepWrapper>

          <!-- ALLGEMEINE DATEN (step Schadentyp) -->
          <div #allgemeineDatenStep>
            <lib-allgemeine-daten-step data-testid="pwSchadenmeldungAllgemeineDaten"
              [formGroup]="allgemeineDatenFormGroup" [target]="target" [schadenGruppen]="schadenGruppen"
              [schadenTyp]="selectedSchadenTyp"></lib-allgemeine-daten-step>
          </div>

          <!-- SPEZIFISCHE DATEN (step SCHADENINFOS)-->
          <div class="formStep" *ngIf="spezifischeDatenVisible$ | async" #spezifischeDatenStep>
            <!-- EC-7966 states that the claim report's date field should appear at first (heading) position -->
            <lib-fields [formGroup]="allgemeineFragenFormGroup" [virtual]="false"></lib-fields>

            <!-- target still technically belongs to allgemeineDaten, -->
            <!-- but should be rendered inside spezifischeDaten in UI -->
            <!-- hence the formGroup allgemeineDatenFormGroup -->
            <ng-container *ngIf="target">
              <div [formGroup]="allgemeineDatenFormGroup">
                <ng-container *ngIf="target.type === 'kunde'" formGroupName="target">
                  <h4 class="fieldtitle">{{'schadenmeldung.kundeId' | translate}}</h4>
                  <lib-fmx-dropdown [availableOptions]="availableKunden" [requiredError]="" [required]="true"
                    appearance="outline" placeholder="{{'schaden.schadenmeldung.unternehmen.title' | translate}}"
                    formControlName="kundeId" data-testid="pwKundeId">
                  </lib-fmx-dropdown>
                </ng-container>

                <ng-container *ngIf="target.type === 'lzr'" formGroupName="target">
                  <h4 class="fieldtitle">{{'schadenmeldung.lzrId' | translate}}</h4>
                  <lib-fmx-dropdown [availableOptions]="availableLzrs" [requiredError]="" [required]="true"
                    appearance="outline" placeholder="{{'schaden.schadenmeldung.betriebsstaette.title' | translate}}"
                    formControlName="lzrId" data-testid="pwLzrId">
                  </lib-fmx-dropdown>
                </ng-container>

                <ng-container *ngIf="target.type === 'ou'" formGroupName="target">
                  <h4 class="fieldtitle">{{'schadenmeldung.ouId' | translate}}</h4>
                  <lib-fmx-dropdown [availableOptions]="availableOus" [sort]="false" [requiredError]=""
                    appearance="outline" placeholder="{{'schaden.schadenmeldung.betriebsstaette.title' | translate}}"
                    formControlName="ouId" [required]="true" data-testid="pwOuId">
                  </lib-fmx-dropdown>
                </ng-container>
              </div>
            </ng-container>
            <lib-spezielle-daten-step [spezielleDatenFormGroup]="spezielleFragenFormGroup"
              [isArztHaftpflichtTyp]="isArztHaftpflicht"
              data-testid="pwSchadenmeldungSpezifischeDaten"></lib-spezielle-daten-step>
            <button mat-flat-button color="primary" *ngIf="hasNextStep()"
              (click)="displayNextStep($event); activateIndicatorStep(2); " class="nextButton"
              [disabled]="!isSpezifischeDatenCompleted()">
              {{ "app.base.form.button.next" | translate }}
            </button>
          </div>

          <!-- BANKDATEN / ZAHLUNGSEMPFÄNGER (step Kundendaten)-->
          <div class="formStep" *ngIf="bankdatenVisible$ | async" #bankDatenStep>
            <lib-bankverbindung-step [bankFormGroup]="schadenMeldungFormGroup.get('bankdaten')"
              [isUserAnoym]="isAnonymusUser" [bankverbindungFragenConfig]="bankdatenConfig"
              data-testid="pwSchadenmeldungBankdaten">
            </lib-bankverbindung-step>
            <button mat-flat-button color="primary" *ngIf="hasNextStep()"
              (click)="displayNextStep($event); activateIndicatorStep(2);" class="nextButton"
              [disabled]="!isBankdatenCompleted()">
              {{ "app.base.form.button.next" | translate }}
            </button>
          </div>

          <!-- UNTERLAGEN (step Unterlagen)-->
          <div class="formStep" *ngIf="unterlagenVisible$ | async" #unterlagenStep>
            <lib-unterlagen-step [unterlagenFormGroup]="schadenMeldungFormGroup.get('unterlagen')"
              data-testid="pwSchadenmeldungUnterlagen">
            </lib-unterlagen-step>
            <button mat-flat-button color="primary" *ngIf="hasNextStep()"
              (click)="displayNextStep($event); activateIndicatorStep(3);" class="nextButton">
              <ng-container *ngIf="filesLength > 0">
                <!-- {{ "app.base.form.button.next" | translate }} -->
                {{ "schaden.schadenmeldung.form.unterlagen.button.has.files" | translate }}
              </ng-container>
              <ng-container *ngIf="filesLength === 0">
                {{ "schaden.schadenmeldung.form.unterlagen.button.has.no.files" | translate }}
              </ng-container>
            </button>
          </div>

          <!-- TENANTFIELDS (step individuelle Felder) -->
          <div class="formStep" *ngIf="tenantfieldVisible$ | async" #tenantFieldsStep>
            <lib-tenantfelder-daten-step [tenantfieldFormGroup]="schadenMeldungFormGroup.get('tenantFields')">
            </lib-tenantfelder-daten-step>
            <button mat-flat-button color="primary" *ngIf="hasNextStep()"
              (click)="displayNextStep($event); activateIndicatorStep(4);" class="nextButton"
              [disabled]="!isTenantfieldDatenCompleted()">
              {{ "app.base.form.button.next" | translate }}
            </button>
          </div>


          <!-- VERSICHERUNGSNEHMER (step KUNDENDATEN)-->
          <div #versicherungsNehmerStep class="formStep"
            *ngIf="isAnonymusUser && dezentralUuid === undefined && this.vnVisible$ | async">
            <lib-versicherungsnehmer-step [kundennummerFormControl]="getKundenNummerCtrl()"
              [versicherungsnehmerFormControl]="getVersicherungsnehmerCtrl()"
              data-testid="pwSchadenmeldungVersicherungsNehmer"></lib-versicherungsnehmer-step>

            <button mat-flat-button color="primary" *ngIf="hasNextStep()"
              (click)="displayNextStep($event); activateIndicatorStep(5);" class="nextButton"
              [disabled]="!isVersicherungsNehmerCompleted()">
              {{ "app.base.form.button.next" | translate }}
            </button>
          </div>

          <!-- ANSPRECHPARTNERDATEN (step ansprechpartner)-->
          <div class="formStep" *ngIf="isAnonymusUser && ansprechpartnerVisible$ | async" #ansprechpartnerDatenStep>
            <lib-ansprechpartner-daten-step
              [ansprechpartnerFormGroup]="schadenMeldungFormGroup.get('ansprechpartnerDaten')"
              data-testid="pwSchadenmeldungAnsprechpartnerDaten"></lib-ansprechpartner-daten-step>
            <button mat-flat-button color="primary" *ngIf="hasNextStep()"
              (click)="displayNextStep($event); activateIndicatorStep(6);" class="nextButton"
              [disabled]="!isAnsprechpartnerDatenCompleted()">
              {{ "app.base.form.button.next" | translate }}
            </button>
          </div>

          <!-- BESCHREIBUNG IST IN DEN SPEZIELLEN FELDERN -->

          <!-- DATENSCHUTZHINWEIS (step Datenschutz)-->
          <div class="formStep" *ngIf="isAnonymusUser && datenschutzVisible$ | async" #datenschutzSeiteStep>
            <lib-datenschutz-step [datenschutzSeite]="schadenMeldungFormGroup.get('datenschutzSeite')" [firma]="firma"
              [openDatenschutzSeite]="true" data-testid="pwSchadenmeldungDatenschutzSeite">
            </lib-datenschutz-step>
          </div>
        </div>

        <div *ngIf="captchaRequired" fxLayoutAlign="center center">
          <div class="box" formGroupName="captchaSolutionData" fxLayout="column">
            <div fxLayout="row">
              <div class="captchaHeadline">
                <span>{{ "schadenmeldung.captcha_code" | translate }}</span>
              </div>
              <span class="spacer"></span>

              <button (click)="loadCaptcha(true)" mat-icon-button type="button">
                <mat-icon>cached</mat-icon>
              </button>
            </div>

            <div fxLayout="column" fxLayoutAlign="center center">
              <div>
                <img [src]="captchaImagePath" style="width: auto; height: auto" />
              </div>

              <mat-form-field [appearance]="appearance">
                <input class="captchaInput" formControlName="proposedSolution" matInput maxlength="5" />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div [ngClass]="{ sendWrapper: true, hidden: !schadenMeldungFormGroup.valid }" #sendenStep
          fxLayoutAlign="end center">
          <button color="primary" [disabled]="!schadenMeldungFormGroup.valid" mat-flat-button type="button"
            data-testid="pwSchadenmeldungSubmit" (click)="onSubmit()">
            {{ "schadenmeldung.send" | translate }}
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>