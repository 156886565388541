import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { FieldConfigInputDto } from "connect-frontend-service";
import { AbstractSchadenMeldungFunction } from "../AbstractSchadenMeldungFunction";

@Component({
  selector: "lib-bankverbindung-step",
  templateUrl: "./bankverbindung-step.component.html",
  styleUrls: ["./bankverbindung-step.component.scss"]
})
export class BankverbindungStepComponent extends AbstractSchadenMeldungFunction {

  @Input()
    bankFormGroup: UntypedFormGroup;

  @Input()
    isUserAnoym: boolean;

  @Input()
    bankverbindungFragenConfig: FieldConfigInputDto[];

  constructor(translate: TranslateService,
  ) {
    super(translate);
  }

  public infoToolTip = this.translate.instant("schaden.schadenmeldung.formular.bankdaten.zahlungsempfaenger.tooltip");
  public preventNonNumericalInput(event: KeyboardEvent): void {
    /**
     * This is a workaround for Firefox Bug with input type="number"
     * which allows non numerical input. We cannot use the Validators.pattern from Angular, since
     * it does not work with input type="number" in Firefox. If we want to use Validators.pattern we have to use input type="text"
     * but hten we loose the min max Validators and the number keyboard on mobile devices ...
     */
    if (!event.key.match(/^[0-9.,]*$/)) {
      event.preventDefault();
    }
  }

  getFormFieldErrorMessageComponent(key: string): string {
    const group = this.bankFormGroup.get(key);
    return this.getFormFieldErrorMessage(group.errors, null, this.getMaxLength(key));
  }

  showVorsteuerabzug() {
    const control = this.bankFormGroup.get("vorsteuerabzug");

    if (this.bankFormGroup.get("vorsteuerabzugberechtigt")) {
      control.enable();
    } else {
      control.disable();
    }
  }

  getMaxLength(keyName: string): number {
    return this.bankverbindungFragenConfig?.find(obj => obj?.key === keyName)?.maxLength || null;
  }

}
